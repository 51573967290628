/**
 * @file
 * Positioning for a responsive layout.
 *
 * Define CSS classes to create a fluid grid layout with optional sidebars
 * depending on whether blocks are placed in the left or right sidebars.
 *
 * This layout uses the Zen Grids plugin for Compass: http://zengrids.com
 */

// We are going to create a fluid grid with 1, 3, or 5 columns and 20px gutters
// between columns (applied as 10px of left/right padding on each column).
$zen-columns:  1;
$zen-gutters: 20px !default;

// If you need IE6/7 support for box-sizing: border-box (default), see _base.scss
//$zen-box-sizing: content-box;

// You can generate more efficient CSS if you manually apply the
// zen-grid-item-base mixin to all grid items from within a single ruleset.
$zen-auto-include-item-base: false;
// $zen-auto-include-flow-item-base: false;



/**
 * Center the page.
 *
 * For screen sizes larger than 1200px, prevent excessively long lines of text
 * by setting a max-width.
 */
#header,
#navigation,
#main,
.region-bottom,
.footer-inner {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
}

/* Apply the shared properties of grid items in a single, efficient ruleset. */
#header,
#content,
#navigation,
.region-sidebar-first,
.region-sidebar-second,
#footer {
  // See the note about $zen-auto-include-item-base above.
  @include zen-grid-item-base();
}

/* Containers for grid items and flow items. */
#header,
#main,
#footer {
  @include zen-grid-container();
}

/* Navigation bar */
@media all and (min-width: 480px) {
  #main {
    /* Move all the children of #main down to make room. */
    position: relative;
  }
  #navigation {
    /* Move the navbar up inside #main's padding. */
    top: 0;
    width: $zen-grid-width;
  }
}

/**
 * Use 3 grid columns for smaller screens.
 */
@include zen-layout($columns: 3) {
  @media all and (min-width: 768px) {

    /**
     * The layout when there is only one sidebar, the left one.
     */
    .sidebar-first {
      /* Span 2 columns, starting in 2nd column from left. */
      #content {
        @include zen-grid-item(2, 2);
      }

      /* Span 1 column, starting in 1st column from left. */
      .region-sidebar-first {
        @include zen-grid-item(1, 1);
      }
    }

    /**
     * The layout when there is only one sidebar, the right one.
     */
    .sidebar-second {
      /* Span 2 columns, starting in 1st column from left. */
      #content {
        @include zen-grid-item(2, 1);
      }

      /* Span 1 column, starting in 3rd column from left. */
      .region-sidebar-second {
        @include zen-grid-item(1, 3);
      }
    }

    /**
     * The layout when there are two sidebars.
     */
    .two-sidebars {
      /* Span 2 columns, starting in 2nd column from left. */
      #content {
        @include zen-grid-item(2, 2);
      }

      /* Span 1 column, starting in 1st column from left. */
      .region-sidebar-first {
        @include zen-grid-item(1, 1);
      }

      /* Start a new row and span all 3 columns. */
      .region-sidebar-second {
        @include zen-grid-item(3, 1);
        //@include zen-nested-container(); // Since we're making every block in this region be a grid item.
        //@include zen-clear();

        /* Apply the shared properties of grid items in a single, efficient ruleset. */
        .block {
          @include zen-grid-item-base();
        }
        /* Span 1 column, starting in the 1st column from left. */
        .block:nth-child(3n+1) {
          @include zen-grid-item(1, 1);
          //@include zen-clear();
        }
        /* Span 1 column, starting in the 2nd column from left. */
        .block:nth-child(3n+2) {
          @include zen-grid-item(1, 2);
        }
        /* Span 1 column, starting in the 3rd column from left. */
        .block:nth-child(3n) {
          @include zen-grid-item(1, 3);
        }
      }
    }
  }
}
@media all and (min-width: 960px) {


}
