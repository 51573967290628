// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$facebook-name: 'facebook';
$facebook-x: 0px;
$facebook-y: 0px;
$facebook-offset-x: 0px;
$facebook-offset-y: 0px;
$facebook-width: 182px;
$facebook-height: 36px;
$facebook-total-width: 182px;
$facebook-total-height: 56px;
$facebook-image: '../images/social-sprite.png';
$facebook: (0px, 0px, 0px, 0px, 182px, 36px, 182px, 56px, '../images/social-sprite.png', 'facebook', );
$instagram-name: 'instagram';
$instagram-x: 0px;
$instagram-y: 36px;
$instagram-offset-x: 0px;
$instagram-offset-y: -36px;
$instagram-width: 20px;
$instagram-height: 20px;
$instagram-total-width: 182px;
$instagram-total-height: 56px;
$instagram-image: '../images/social-sprite.png';
$instagram: (0px, 36px, 0px, -36px, 20px, 20px, 182px, 56px, '../images/social-sprite.png', 'instagram', );
$twitter-name: 'twitter';
$twitter-x: 20px;
$twitter-y: 36px;
$twitter-offset-x: -20px;
$twitter-offset-y: -36px;
$twitter-width: 20px;
$twitter-height: 20px;
$twitter-total-width: 182px;
$twitter-total-height: 56px;
$twitter-image: '../images/social-sprite.png';
$twitter: (20px, 36px, -20px, -36px, 20px, 20px, 182px, 56px, '../images/social-sprite.png', 'twitter', );
$youtube-name: 'youtube';
$youtube-x: 40px;
$youtube-y: 36px;
$youtube-offset-x: -40px;
$youtube-offset-y: -36px;
$youtube-width: 20px;
$youtube-height: 20px;
$youtube-total-width: 182px;
$youtube-total-height: 56px;
$youtube-image: '../images/social-sprite.png';
$youtube: (40px, 36px, -40px, -36px, 20px, 20px, 182px, 56px, '../images/social-sprite.png', 'youtube', );
$spritesheet-width: 182px;
$spritesheet-height: 56px;
$spritesheet-image: '../images/social-sprite.png';
$spritesheet-sprites: ($facebook, $instagram, $twitter, $youtube, );
$spritesheet: (182px, 56px, '../images/social-sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
