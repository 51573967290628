/**
 * @file
 * Styles are organized using the SMACSS technique. @see http://smacss.com/book/
 *
 * When you turn on CSS aggregation at admin/config/development/performance, all
 * of these @include files will be combined into a single file.
 */

@import "fonts";

/* Import Sass mixins, variables, Compass modules, etc. */
@import "init";

/* HTML element (SMACSS base) rules */
@import "normalize";


/* Layout rules */
@import "layouts/responsive";

/* Component (SMACSS module) rules */
@import "components/misc";
// Optionally, add your own components here.
@import "print";

/* SMACSS theme rules */
@import "theme";
/* @import "theme-B"; */
