/* General */

@import "social-sprite";

body {
  @extend .body;
}

a {
  color: $brown;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

h1.page__title {
  background: $light-grey;
  font-size: 43px;
  font-weight: 400;
  line-height: 73px;
  padding: 70px 0;
  text-align: center;
}

.header__logo {
  display: block;
  text-align: center;
}

.apply-now {
  background: #b39862 none repeat scroll 0 0;
  color: #ffffff;
  display: inline-block;
  font-size: 16px !important;
  font-weight: normal;
  line-height: 25px;
  padding: 20px;
  text-decoration: none;
  white-space: nowrap;
}

#navigation {
  .menu,
  .links {
    display: block;
    padding: 0;
    text-align: center;
    li {
      display: inline-block;
      float: none;
      margin: 0 10px;
      a {
        color: $black;
        font-size: 26px;
        text-decoration: none;
        border-bottom: 3px solid #fff;
        font-family: "Oswald", Arial, Helvetica, "Nimbus Sans L", sans-serif;
        font-weight: 400;
        &:hover {
          color: $black;
          border-bottom: 3px solid #ec181f;
          cursor: pointer;
        }
      }
    }
    li.active a {
      border-bottom: 3px solid #ec181f;
    }
  }
}

#content {
  min-height: 400px;
  padding-bottom: 100px;
  padding-top: 35px
}

#footer {
  @include clearfix;
  background: #000;
  color: #fff;
  p {
    margin: 0;
  }
}

/* Blocks */

#block-block-1 {
  text-align: center;
  margin-bottom: 30px;
  h2.block__title {
    padding: 30px 0 30px 0;
  }
  img {
    height: auto !important;
    margin: 0 10px 10px 10px;
    vertical-align: middle;
  }
}

#block-block-2 {
  text-align: center;
}

#block-block-3 {
  position: relative;
  margin: 0;
  width: 97%;
  padding-left: 1.5%;
  padding-right: 1.5%;
  padding-top: 20px;
  text-align: center;
  min-height: 560px;
  font-family: "Oswald", Arial, Helvetica, "Nimbus Sans L", sans-serif;
  font-weight: 400;
  background: url("../images/microphone-red.png") center top no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  .value {
    margin-bottom: 10px;
    p{
      display: block;
      width: 100%;
      padding: 0;
    }
  }
  h1 {
    color: $white;
    font-size: 60px;
    height: 100%;
    line-height: normal;
    margin: 0;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    top: 12%;
    width: 100%;
  }
  p a {
    @extend .apply-now;
    bottom: -80px;
    margin-left: -58px;
    position: absolute;
  }
  img {
    height: auto !important;
    vertical-align: top;
  }
  p {
    text-align: center;
    margin: 15px 0;
    display: inline-block;
    width: 23%;
    vertical-align: top;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    padding-left: 0.5%;
    padding-right: 0.5%;
  }
}

@media all and (max-width: 768px) {
  #block-block-3 {
    min-height: 540px;
  }
  #block-block-3 p {
    width: 100%;
    padding: 0;
    margin: 5px 0;
    font-size: 19px;
    line-height: 29px;
  }
}


@media all and (max-width: 667px) {
  #block-block-3 {
    min-height: 520px;
  }
}


@media all and (max-width: 568px) {
  #block-block-3 p {
    font-size: 16px;
    line-height: 26px;
    width: 97%;
  }
  #navigation .menu li a,
  #navigation .links li a {
    font-size: 18px;
  }
  #navigation .menu li,
  #navigation .links li {
    margin: 0 2px 10px 2px;
  }
}

#block-block-4 {
  margin-bottom: 50px;
}

#block-block-6 {
  text-align: center;
  p {
    margin: 0;
  }
}

#block-social-media-links-social-media-links {
  text-align: center;

  h2.block__title {
    display: inline;
    font-family: $merriweather;
    font-size: 16px;
    font-weight: normal;
    margin: 0 10px 0 0;
    vertical-align: baseline;
  }
  .social-media-links {
    margin: 0;
    text-align: center;
    li {
      padding: 0;
      a {
        display: inline-block;
        height: 36px;
        margin: 0 1px;
        overflow: hidden;
        text-indent: -1000px;
        vertical-align: baseline;
        width: 182px;
        img {
          display: none;
        }
      }
      &.facebook a {
        @include sprite($facebook);
      }
      &.twitter a {
        @include sprite($twitter);
      }
      &.youtube a {
        @include sprite($youtube);
      }
      &.instagram a {
        @include sprite($instagram);
      }
    }

  }
}

.view-judges,
.view-past-winners {
  &.view-display-id-page_1 {
    .view-header {
      font-style: italic;
      margin-bottom: 40px;
    }
    .view-content {
      .views-row {
        margin-bottom: 40px;
        .views-field-title {
          font-style: italic;
          font-weight: 400;
          margin-bottom: 30px;
          text-align: center;
        }
        .views-field-field-image {
          margin-bottom: 30px;
          text-align: center;
          img {
            vertical-align: top;
          }
        }
        .views-field-body {

        }
      }
    }
  }
}

#block-views-judges-block,
#block-views-past-winners-block{
  background: $light-grey;
  text-align: center;
  margin: 0;
  padding: 35px 0 75px 0;
  .view-content {
    @include clearfix;
    max-width: 1200px;
    margin: 0 auto;
    padding: 35px 0 0 0;
    .views-row {
      display: inline-block;
      margin: 0 2% 20px 2%;
      width: 210px;
    }
  }
  .views-field-title {
    font-size: 14px;
    line-height: 20px;
    a {
      color: $grey;
      &:hover {
        color: #666666;
        text-decoration: none;
      }
    }
  }
}

.block {
  .ui-widget {
    font-family: $merriweather;
    font-size: 1em;
  }
  .ui-state-default,
  .ui-widget-content .ui-state-default,
  .ui-widget-header .ui-state-default {
    background: $red;
    border-color: $red;
    a {
      color: $white;
    }
    .ui-icon {
      background: url(../images/icn-plus.png) top left no-repeat;
    }
  }
  .ui-state-active {
    .ui-icon {
      background: url(../images/icn-minus.png) top left no-repeat;
    }
  }
  .ui-widget-content {
    background: #f8f8f8;
    font-size: 0.8em;
  }
  .ui-accordion {
    .ui-accordion-header {
      margin-top: 4px;
      .ui-accordion-header-icon {
        margin-top: -8px;
        left: auto;
        right: 0.5em;
        position: absolute;
        top: 50%;
      }
    }
    .ui-accordion-content {
      padding: 15px 12px;
      p {
        margin: 0;
      }
    }
    .ui-accordion-icons {
      padding: 8px 24px 8px 8px;
    }
  }
  .ui-corner-all {
    border-radius: 3px;
  }
  .jquery-ui-filter-accordion {

  }
}

/* Pages */

.front {
  h1.page__title {
    display: none;
  }
  #content {
    min-height: 0px;
    padding-bottom: 0;
    padding-top: 0;
  }
}

.node-7 {
  margin-bottom: 20px;
  .field-name-body {
    .field-item {
      .media_embed {
        height: 0px;
        padding-bottom: 56.2%;
        position: relative;
        width: 100%;
        > iframe {
          height: 100% !important;
          position: absolute;
          width: 100% !important;
        }
      }
    }
  }
}

.node-type-submission {
  .view-mode-full {
    .field {
      margin-bottom: 1em;
    }
  }
  &.role-judge,
  &.role-final-judge {
    #header,
    #navigation {
      display: none;
    }
  }
}

.page-node-add-submission {
  h1.page__title {
    font-size: 1px;
    line-height: 1px;
    padding: 0;
    opacity: 0;
  }
  .tabledrag-toggle-weight-wrapper {
    display: none;
  }
  .form-field-name-field-uploads-why-video-link,
  .form-field-name-field-uploads-live-perf-link {
    .form-item {
      margin: 0.5em 0;
      .description {
        font-style: italic;
      }
    }
  }
  .multipage-pane-title {
    em.placeholder {
      font-style: normal;
    }
  }
}

/* Responsiveness */

@media all and (min-width: 480px) {
  .view-judges,
  .view-past-winners {
    &.view-display-id-page_1 {
      .view-content {
        @include clearfix;
        .views-row {
          @include clearfix;
          .views-field-field-image {
            float: left;
            width: 150px;
          }
          .views-field-title,
          .views-field-body {
            margin-left: 170px;
            text-align: left;
          }
        }
      }
    }
  }
}

@media all and (min-width: 480px) and (max-width: 959px) {
  #block-block-1 {
    img {
      margin: 10px 20px;
    }
  }
}

@media all and (min-width: 768px) {

}

@media all and (min-width: 960px) {
  #block-block-1 {
    margin-bottom: 170px;
    h2.block__title {
      padding: 30px 0 70px 0;
    }
    img {
      margin: 10px 2%;
    }
  }
  #block-block-2 {
    float: left;
    margin-top: 30px;
    text-align: left;
  }
  #block-block-3 {
    p a {
      bottom: auto;
      top: 68%;
    }
  }
  #block-social-media-links-social-media-links {
    float: right;
    margin-top: 12px;
    text-align: right;
  }
  .node-7 {
    .field-name-body {
      .field-item {
        @include clearfix;
        .media_embed {
          float: right;
          height: 360px;
          margin: 0;
          padding: 0;
          width: 640px;

        }
      }
    }
  }
}

@media all and (min-width: 1024px) {
  #block-block-3 {
    h1 {
      top: 15%;
    }
    p a {
      bottom: auto;
      top: 68%;
    }
  }
}

@media all and (min-width: 1200px) {
  #block-block-1 {
    img {
      margin: 10px 2% 20px 2%;
      &:first-child {
      }
      &:last-child {
      }
    }
  }
  #block-block-3 {
    h1 {
      top: 15%;
    }
    p a {
      bottom: auto;
      top: 68%;
    }
  }
}

h1#page-title,
.region-before-content {
  clear: both;
}

.header-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  header.header {
    float: left;
    margin-top: 40px;
    margin-bottom: 40px;
    .header__logo {
      float: left;
    }
  }
  #navigation {
    float: right;
    text-align: right;
    margin-top: 122px;
    width: auto;
  }
}

@media screen and (max-width: 1080px) {
  .header-wrapper {
    header.header {
      float: none;
      margin-top: 20px;
      margin-bottom: 20px;
      .header__logo {
        float: none;
      }
    }
    #navigation {
      float: none;
      text-align: center;
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }
}

@media screen and (max-width: 480px) {
  .header-wrapper #navigation {
    margin-bottom: 0;
  }
}

.front .region-before-footer h2,
.front #main  h2 {
  color: #ec181f;
  font-size: 36px;
  text-align: center;
}

@media screen and (max-width: 568px) {
  .front .region-before-footer h2,
  .front #main h2 {
    font-size: 30px;
  }
}

#block-views-past-winners-block {
  .views-field-field-image img {
    border: 2px solid #000;
  }
}

.front .node-page.view-mode-full {
  .field-name-body {
    p {
      font-size: 16px;
      line-height: 26px;
      margin: 10px 0;
    }
    p:first-child {
      margin-top: 0;
    }
    .media_embed {
      float: right;
      width: 52%;
    }
    .red p {
      color: #ec181f;
      font-size: 26px;
      line-height: 36px;
      font-family: "Oswald", Arial, Helvetica, "Nimbus Sans L", sans-serif;
      font-weight: 500;
    }
    .text {

    }
  }
}

#block-block-7 {
  background: #313131;
  color: #fff;
  font-size: 16px;
  padding-top: 30px;
  margin-bottom: 0;
  display: inline-block;
  width: 97%;
  padding-left: 1.5%;
  padding-right: 1.5%;
  min-height: 480px;
  background: url("../images/microphone-gray.png") center top no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  img {
    border: 2px solid #fff;
    float: left;
    margin-right: 30px;
    margin-bottom: 30px;
    height: auto;
    width: 100%;
  }
  .inner-block {
    max-width: 1200px;
    margin: 0 auto;
    display: block;
  }
  h3 {
    font-weight: 400;
  }
}

@media screen and (max-width: 1280px) {
  .front .node-page.view-mode-full {
    .field-name-body {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

@media screen and (max-width: 1080px) {
  .front .node-page.view-mode-full {
    .field-name-body {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .front .node-page.view-mode-full {
    .field-name-body {
      h2 {
        margin-bottom: 10px;
      }
      .media_embed {
        float: none;
        width: 100%;
        margin-bottom: 20px;
      }
      .text {
        float: none;
        width: 97%;
        padding-left: 1.5%;
        padding-right: 1.5%;
      }
    }
  }

  #block-block-7 {
    padding-top: 0;
    img {
      border: 2px solid #fff;
      float: none;
      margin: 20px auto;
      display: inline-block;
      width: 98% !important;
      height: auto !important;
    }
    .inner-block {
      max-width: 1200px;
      margin: 0 auto;
      display: block;
      text-align: center;
    }
    h3 {
      text-align: center;
    }
    .text {
      text-align: left;
    }
  }
}

@media screen and (max-width: 568px) {
  .front .node-page.view-mode-full .field-name-body p {
    font-size: 14px;
    line-height: 24px;
  }
  .front .node-page.view-mode-full .field-name-body .red {
    padding-left: 10px;
    padding-right: 10px;
  }
  .front .node-page.view-mode-full .field-name-body .red p {
    font-size: 20px;
    line-height: 30px;
  }
  #block-block-7 {
    padding-top: 20px;
    width: 97%;
    padding-left: 1.5%;
    padding-right: 1.5%;
    min-height: 400px;
    img {
      margin-bottom: 0;
      margin-top: 0;
    }
  }
  .front .node-page.view-mode-full .field-name-body .text {
    width: 93%;
    margin: 0 auto;
    padding: 0;
  }
  #block-block-7 .inner-block {
    width: 97%;
    margin: 0 auto;
  }
}

.front #content {
  padding-left: 0;
  padding-right: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Oswald", Arial, Helvetica, "Nimbus Sans L", sans-serif;
  font-weight: 400;
}
